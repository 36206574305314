
/* VVVVVVVVVVVVVVVVVVVVVV */

.Login a{
    color:#D39C3E;
    text-decoration: none;
    font-family: 'Open Sans';
    font-weight: bold;
}
    .Components{
        margin: 135px auto;
        width: 487px;
        height: 590px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 0px 0px 40px 40px;
        background: white;
    }      
    .Head{
        display: block;
        height: 55px;
        background: #285A64; 
        margin: 0px;
        padding: 0px;
}
    .welcomeText{   
        margin-top: 25px;
        height:100px;
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
   }
    .welcomeText1{   
        text-align: center;
        font-family: 'Open Sans';
        font-weight: bold;
        letter-spacing: 0;
        color: #285A64;
        opacity: 1;
        font-size: 28px;
}
    .welcomeText2{   
        text-align: center;
        font-weight: bold;
        letter-spacing: 0;
        color: #285A64;
        opacity: 1;
        font-size: 24px;
        font-family: 'Open Sans';
    }
     .forgotPasswordText{
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 12px;
        font-family: 'Open Sans';
        letter-spacing: 0;
        opacity: 1;
    }
    .notUserYetText{
        text-align: center;
        font-weight: bold;
        letter-spacing: 0;
        color: #285A64;
        opacity: 1;
        font-size: 24px; 
        margin-top: 30px;
        font-family: 'Open Sans';
    }
    .Components2{
        margin: 135px auto;
        width: 487px;
        height: 336px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 0px 0px 40px 40px;
        background-color: white;     
    }
    .Components3{
        width: 487px;
        height: 365px;
        margin: 135px auto;       
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 0px 0px 40px 40px;
        background-color: white; 
    }
    .verificationContent{
        margin: 40px auto;    
        align-items: center;
        display: flex;
        flex-direction: column;     
    }
    .verificationInputs{
        display: flex;
        text-align: left;
        flex-direction: column;
        justify-content: flex-start;    
        width: 50%;      
        font-size: 16px;
        font-family: 'Open Sans';
        margin: 0 auto;
    }
    .verificationHeader{
        font-size: 24px;
        font-weight: bold;
        font-family: 'Open Sans';
    }
    .Components4{
        width: 487px;
        height: auto;
        margin: 135px auto;       
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 0px 0px 40px 40px;
        background-color: white; 
    }
    .enterYourEmailText{
        text-align: center;
        font-weight: bold;
        letter-spacing: 0;
        color: #285A64;
        opacity: 1;
        font-size: 24px;
        font-family: 'Open Sans';
        margin-top: 40px;
        margin-bottom: 25px;
    }
    .requestNewCode{
            text-align: center;
            font-weight: bold;
            letter-spacing: 0;
            color: #285A64;
            opacity: 1;
            font-size: 24px;
            font-family: 'Open Sans';       
            margin: 0 auto;       
    }
    .loginButton{     
        margin-top: 5px                  
    }

    .nextButton{
        margin-top: 35px 
    }

    .closeButton{
        margin-top: 20px;
    }

    .inputs{
        margin: 5px;
    }

    .infoTextLogin{
        text-align: center;
        letter-spacing: 0;
        color: #285A64;
        opacity: 1;
        font-size: 16px;
        font-family: 'Open Sans';
    }
    .content{
        height: 190px;
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -20px;
    }
    .contentTextLogin{
        width: 487px;
        height: 84px;
        margin:80px auto;
    }