.tabContainer{
  display: flex;
}
.tabItem{
  flex:1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #285A64;
  border: 0.25px solid #CCCCCC;
  cursor: pointer;
  height: 40px;
  font-weight: bold;
}
.tabItem.active{
  border-bottom: 0;
}
