@import '../Dashboard/CurrencyTrader/DossierModal/BuySell.module.scss';
@import '../Dashboard/CurrencyTrader/CurrencyTrader.module.scss';

.CloseIcon {
    color: white;
    font-size: 25px;
    cursor: pointer;
}

.Separator {
    height: 25px;
    border: 1px solid #D39C3E;
    opacity: 0.28;
    margin: 0 5px
}

.Wall {
    background: #285A64;;
    width: 100%;
    height: 40px;
}

.Icon {
    color: white;
    font-size: 23px;
    float: right;
    margin: 9px 15px;
}

.ClientDetails{
    display: flex;
    flex-direction: column;
}

.EditForm{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.Input {
    border: 1px solid #e2dfdf;
    height: 35px;
    margin: 5px;
    text-indent: 10px;
    color: #285A64;
    &:focus{
        outline: none;
    }
}