@import "../dashboard_style";

.dashboardItem{
  margin-top: 27px;
}

/* Borders */
.brLeft{
  border-left:solid 2px #eee;
}
.brTop{
  border-top:solid 2px #eee;
}
.brBottom{
  border-bottom:solid 2px #eee;
}
.brRight{
  border-right:solid 2px #eee;
}

.dashboardHeader{
  img{
    width: 28.75px;
    height: 26px;
  }
}
