.Button {
    background-color: #D39C3E;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    font-weight: bold;
    border-radius: 12px;
    min-width: 100px;
    height: 39px;
    font-size: 16px;
    font-family: 'Open Sans';
}

/* .Button:first-of-type {
    margin-left: 0;
    padding-left: 0;
    margin-right: 0;
    padding-right: 0;
}

.Success {
    color: #5C9210;
}

.Danger {
    color: #944317;
} */

.Buttom:disabled{
    background-color: gray;
    cursor: not-allowed;
}
