@import "../../dashboard_style";
@import "../CurrencyTrader.module.scss";

.cancel_order_button{
  width:fit-content;
}

.no_order_button{
  background: white;
  color: #D39C3E;
  border: 1px solid #D39C3E;
  margin-right: 15px;
}

.cancel_confirmation_div{
 width:100%;
 height:100%;
 background:#ffffffe3;
 border-radius:30px;
 position:absolute;
 top:0px;
 left:0px;
 display: flex;
 align-items: center;
 justify-content: center;
 text-align: center;
 color:#9a2020;
 font-family: 'Open Sans';
 font-weight: bold;
 display: flex;
 flex-direction: column;
}

.button_container{
  text-align: right;
  position: absolute;
  right:25px;
  bottom: 20px;
}

//responsiveness

@media only screen and (max-width: 570px) {
  .hiddenText{
    display: none;
  }
  .order_card_extra_bold_text{
    height: unset;
  }
}
