.Input{
    padding: 0px;
    display:block;   
}

.Label {
    font-weight: bold;
    margin-bottom: 8px;
}

.InputElement {
    outline: none;
    border: none;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    width: 275px;
    height: 33px;
    box-shadow: 0px 0px 4px 1px rgba(240,240,240,1);
    

}

.InputElement:focus {
    outline: none;
    background-color: rgb(235, 235, 235);
    
}

.Invalid{
    border: 1px solid red;
    background-color: #FDA49A;
}

.Invalid:focus{
    border: 1px solid red;
    background-color: #FDA49A;
}

strong{
    color: #D39C3E;
    cursor: pointer;
}
