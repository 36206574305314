.MyAlertModal{
  width: 500px;
  max-width: 92vw;
  position: absolute;
  top: 67px;
  left: -147px;
  box-shadow: 0px 0px 5px 0px #444;
  font-weight: 500;
  border-radius: 0px 0px 20px 20px;
  z-index: 99;
}
.MyAlertModal::before{
  content: '';
  top: -30px;
  left: 136px;
  position: absolute;
  border-style: solid;
  border-width: 0 30px 30px 30px;
  border-color: transparent transparent #285A64 transparent;
}
.modal_header{
  background: #285A64;
  padding: 10px 15px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 20px;
}
.modal_header_title{
  flex: 1;
  text-align: left;
  font-weight: bold;
}
.modal_header_edit{
  cursor: pointer;
}
.modal_body{
  padding: 20px 40px 40px;
  color: #285A64;
  position: relative;
  background: white;
  border-radius: 0px 0px 20px 20px;
}
.modal_hr{
  width: 100%;
  height: 1px;
  background: #CCCCCC;
  margin-top: 15px;
  margin-bottom: 15px;;
}


// Button Type 1
@mixin buttonType {
  border: 1px solid #D39C3E;
  border-radius: 12px;
  text-align: center;
  font: Bold 16px/19px Open Sans;
  letter-spacing: 0;
  display: inline-block;
  padding: 9px 17px;
  cursor: pointer;
  margin-left: 6px;
  margin-right: 6px;
}
.button_type1{
  @include buttonType;
  background: #D39C3E;
  color: #FFFFFF;
}
.button_type2{
  @include buttonType;
  background: white;
  color: #D39C3E;
}

// Saved Container
.SavedContainer{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffffd4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.SavedContainerText{
  text-align: center;
  font: Bold 24px/30px Open Sans;
  letter-spacing: 0;
  color: #285A64;
}
.SavedContainerIcon{
  font-size: 150px;
}
// valute_column
.valute_row{
  display: flex;
  &:nth-child(1){
    .valute_column{
      padding-bottom: 20px;
    }
  }
  &:nth-child(2){
    border-top: solid 1px #ccc;
    .valute_column{
      padding-top: 20px;
    }
  }
}
.valute_column{
  flex: 1;
  .valute_column_title{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
  }
  .valute_column_text{
    font-size: 16px;
    font-weight: bold;
  }
}
.valute_v_seperator{
  width: 1px;
  background: #ccc;
  margin-left: 20px;
  margin-right: 20px;
}
.valute_h_seperator{
  width: 100%;
  background: #ccc;
  height: 1px;
}






.notified_text{
  margin-bottom: 10px;
}
.radio_container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.radio_container_item{
  display: flex;
  position: relative;
}

.valute_name{
  text-align: left;
}
.valute_list_container{
  display: flex;
}
.valute_item_container{
  flex: 1;
  padding: 20px;
  justify-content: flex-start;
}
.bb1{
  border-bottom: solid 1px #CCCCCC;
}
.br1{
  border-right: solid 1px #CCCCCC;
}
.ValuteItem{
  display: flex;
  padding: 5px 0;
  align-items: center;
  justify-content: flex-start;
}
.newITems{
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
}
.icon_changer{
  border-radius: 50%;
  background: #D39C3E;
  color: white;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
  cursor: pointer;
}

.myAlerts_input{
  width: 100%;
  height: 25px;
}
