img#qrcode {
    max-width: 40%;
    width: 40%;
}

#container{
max-width: 300px;
font-family: 'Arial';
color: black;
}

td b font{
          font-size: 100%;
          white-space: nowrap;
        }

.sm_line_height {
  line-height: 1;
  font-size: 9px;
}

table {
  width: 100%;
}

.central_table {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}
