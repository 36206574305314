
.Layout{
  background: url("../assets/img/Blank_map.svg") center center / cover; 
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
}

 
.Layout, p{
    color:#285A64;
    font-family:"Open Sans";
}


/* SCROLLBAR */
/* width */
::-webkit-scrollbar {
    width: 10px;
    background: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cccccc;
    border-radius: 25px;
    background: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(189, 189, 189, 0.555);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(185, 185, 185, 0.61);
  }

  /* Colors */
  .icon_orange{
    color: rgb(211, 156, 62);
  }
