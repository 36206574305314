.info_subheader_v1{
  font-weight:bold;
}
.container{
  display: flex;
  flex-direction: column;
  width:65%;
  margin-left: 0 auto;
  margin-right: 0 auto;
  text-align: left;
}
.toggle {
  display: flex;
  flex-direction: row;
}
.Users {
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
  display: flex;
  padding-right: 10px;
}

.Titles{
  font-weight: bold;
  font-size: 14px;
  width: 110%;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}

.example{
  width: 100px;
  text-align: center;
}


input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.react-switch-label {
	cursor: pointer;
	text-indent: -999px;
	width: 40px;
	height: 20px;
	background: #285A64;
	display: block;
	border-radius: 100px;
	position: relative;
}

.react-switch-label:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 5px;
	width: 15px;
	height: 15px;
	background: white;
	border-radius: 50%;
	transition: 0.2s;
}

input:checked + .react-switch-label {
	background: #D39C3E;
}

input:checked + .react-switch-label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

input:focus {
	outline: none !important;
}


.CustomConfirmationDialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #285A64;
    font-family: "Open Sans";
    background: white;
    border: 0.5px solid rgb(204, 204, 204);
    border-radius: 0 0 24px 24px;
    padding: 0 26px 26px;
    height: 310px;
    width: 447px;
	  z-index: 9999;
}

div.react-confirm-alert-overlay {
    z-index: 9999;
}

.DeleteEmployee{
  color: #D39C3E;
  align-self: center;
  padding-right: 10px;
}