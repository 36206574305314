.Privacy{
    position: fixed;
    top: 15%;
    left: 10%;
    right: 10%;
    width: 75%;
    margin: 0 auto;  
}

.Component{
    height: 750px;
    text-align: center;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 0px 0px 40px 40px;
    position: absolute;
    overflow: auto;  
}


.Head{
    height: 60px;
    background: #285A64; 
    margin: 0px;
    padding: 0px;
  
}

.Head .Exit{
    position: absolute;
    left:10px;
    top:5px;
    margin: 0 auto;
}


.firstTitle{
    font-family: 'Open Sans';
    font-size: 26px;
    font-weight: bold;
    margin-top: 25px;
    
}
.singleInput{
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;   
    align-content: center;
    padding: 5px;
  
}

.container{
    padding: 30px;
    width: 100%;
   
    flex-direction: column;
}
.firstForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
}
.privacyStatements{
    font-size: 20px;
    text-align: left;
}