@import '../../../MarketRates/MarketRates.module.scss';

.StartingPopup {
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ModalItem{
    display: flex;
    background-color: white;
    height: 100%;
    width: 100%;
    margin-right: 10px;
    box-shadow: 0px 0px 5px 0px #444;
    border-radius: 0px 0px 20px 20px;
}

.StartingModal {
    flex: 1;
}

.DailySettingsModal{
    flex: 1;
}
