.four_dots_container{
  display: flex;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.four_dots_container i{
  color:#D39C3E;
}
.four_dots_container i:nth-child(1){
  position: absolute;
  top: 0;
  left: 0;
}
.four_dots_container i:nth-child(2){
  position: absolute;
  top: 0;
  right: 0;
}
.four_dots_container i:nth-child(3){
  position: absolute;
  bottom: 0;
  left: 0;
}
.four_dots_container i:nth-child(4){
  position: absolute;
  bottom: 0;
  right: 0;
}
