
/* Register  */

.Register li{
    width: 54%;
    display:inline-block;
    padding: 0px;
    text-align: center;

}
.Components{
    margin: 50px 100px auto;
    position: absolute;
    width: 80%;
    display:inline-block;

}
.scroll{
    width: 5px;

}
.Component{
   overflow: hidden;
    width: 490px;
    text-align: center;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 0px 0px 40px 40px;
    position: absolute;

}

.Position0{
    left:0%;
    z-index: 7;
}

.Position1{
    left:15%;
    z-index: 6;
}

.Position2{
    left:30%;
    z-index: 5;
}

.Position3{
    left:50%;
    z-index: 4;
}

.Position4{
    left:65%;
    z-index: 3;
}

.Position5{
    left:80%;
    z-index: 2;
}

.Position6{
    left:45%;
    z-index: 1;
}

.Position7{
    left:45%;
    z-index: 0;
}
.Position8{
    left:45%;
    z-index: 0;
}

.Head{
    height: 60px;
    background: #285A64;
    margin: 0px;
    padding: 0px;
}

.Active{
    z-index:20;
    width: 490px;
    min-height: 720px;
    margin-top: -25px;
    overflow-y: auto;
}
.Disabled{
    filter: blur(0.8px);
    height:600px;
}

.Final{
    z-index:100;
    width: 468px;
    height: 294px;
    top:100px;
}
.Header{
    height: 50px;
    border: 1px solid black;
}
