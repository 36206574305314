
.SearchBar {
    margin-top: 20px;
}

.SearchBar i {
    position: absolute;
}

.SearchIcon {
    margin: 11px 0 0 7px;
}

.SearchInput {
    border: 1px solid #e2dfdf;
    // border-radius: 3px;
    height: 35px;
    width: 420px;
    text-indent: 33px;
    &:focus{
        outline: none;
    }
}

.SearchItems {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 77px;
    margin-top: 12px;
    font-weight: bold;
    margin-right: 50px;
    max-height: 450px;
    overflow: auto;
}
