@import "../../../containers/Modals/modals.scss";

.personalizations_modal{
  width: 847px;
  right: -25px;
  top: 70px;
  max-width: 92vw;
  &::before {
    top: -30px;
    right: 5px;
  }
}

.option_item{
  width: 100%;
  color: #285A64;
  font-size: 14px;
}
