.Toolbar:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.Toolbar {
    display: inline-block;
    background: #285A64;
    margin: 0px;
    padding: 0px;
    width: 100%;
}
/* start commented backslash hack \*/
* html .Toolbar { height: 1%; }
.Toolbar { display: block; }
/* close commented backslash hack */

.RightNav{
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 10px;
    margin: 0px;
    padding: 0px; */
}

.footerToolbar{
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    height:50px;
    width: 100%;
    color: white;
    justify-content: space-evenly;
}

.NavigationItems{
    float: right;
    margin: 0px;
    padding: 0px;
}

.ProfileSettings{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    margin-right:30px;
    height: 54px;

}

.IconColor{
    color: white
}

.ClientRegistry{
    cursor: pointer;
}
