@import "../../../../Modals/modals.scss";
@import "../../../dashboard_style.scss";

.personalizations_modal{
  width: 600px;
  right: -25px;
  top: 70px;
  max-width: 92vw;
  &::before {
    top: -30px;
    right: 5px;
  }
}

.CurrencyTrader_formRadio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    background-color: #fff;
    color: #fff;
    height: 15px;
    width: 15px;
    border: 0.5px solid #285A64;
    border-radius: 50px;
    cursor: pointer;
    margin-right: 7px;
    outline: none;
}

input {
    color: #285A64 !important;
    font-size: 15px !important;
}

input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

input:checked[type="radio"]
{
background: #285A64;
}
