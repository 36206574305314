@import "../../CashManager.module.scss";

.nr_format_input{
  border: none;
  width: 80%;
  border-bottom: 1px solid #CCCCCC;
  outline: none;
}

.formRadio{
   height: 13px;
   width: 13px;
}

.display_flex_1{
  display: flex;
  flex-direction: row;
  flex: 1;
}

.display_flex_column_1{
  display: flex;
  flex-direction: row;
  flex: 1;
}

.display_flex_end{
  display: flex;
  flex-direction: row;
  flex: 1;
}
