.SearchEngine{
    width: 440px;
    align-self: center;
 }

.SearchInput{
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 0.5px solid #CCCCCC;
    height: 35px;
    width: 100%;
    max-width: 593px;
    text-indent: 35px;
    font-size: 20px;
    font-family: Open Sans;
    color: #CCCCCC;
    // opacity: 0.3;
    &:focus{
        outline: none;
    }
    &::placeholder{
        opacity: 0.7;
    }
}

.SearchEngine i {
    position: absolute;
    color: #CCCCCC;
    margin: 11px 0 0 7px;
}