@import "../modals.scss";

.calendar_modal{
  top: 67px;
  width: 874px;
  max-width: 92vw;
  left: -800px;
  &::before {
    top: -30px;
    right: 20px;
  }
  .modal_body{
    padding: 20px 40px 40px;
  }
}

@media only screen and (max-width: 991px) {
  .calendar_modal{
    width: 410px;
    left: -334px;
  }
}

@media only screen and (max-width: 438px) {
  .calendar_modal{
    width: 410px;
    left: -320px;
  }
}

@media only screen and (max-width: 415px) {
  .calendar_modal{
    width: 410px;
    left: -300px;
  }
}

@media only screen and (max-width: 390px) {
  .calendar_modal{
    width: 410px;
    left: -280px;
  }
}

@media only screen and (max-width: 365px) {
  .calendar_modal{
    width: 410px;
    left: -270px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1765px) {
  .calendar_modal{
    width: 620px;
    left: -542px;
  }
}

.calendar_modal_header{
  width: 100%;
  box-sizing: border-box;
  display: flex;
}
.cm_row{
  display: flex;
  width: 100%;
}
.cm_text{
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}
.cm_input{
  flex: 1;
  input{
    width: 100%;
    text-align: right;
    font-family: 'Open Sans';
    letter-spacing: 0;
    color: #285A64;
    border:solid 1px #cccccc;
    &::placeholder{
      color: #cccccc;
    }
  }
}
.calendar_modal_header_text{
  flex: 1;
}
.calendar_modal_header_left_icon{
  margin-right: 15px;
}
.miniCalendar{
  width: 100%;
  border: solid 1px #cccccc;
  .miniCalendarHeader{
    height: 30px;
    display: flex;
    align-items: center;
    background: #285A64;
    color:white;
    padding-left:10px;
    padding-right:10px;
    .mini_arrows{
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        margin-left: 0;
      }
    }
    .monthName{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 15px;
      font-family: 'Open Sans';
    }
  }
  .miniCalendarBody{
    padding: 10px 20px;
  }
  .weekDay{
    display: flex;
    .weekDayItem{
      flex: 1;
      text-align: center;
    }
  }
  .DateDay{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    .DateDayNumber{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25px;
    }
  }
}
