.firstTitle{
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
}


.container{

    width: 100%;

    flex-direction: column;
}
.firstForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    width: 100%;
}
.secondForm{
    margin-top:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;

}
.singleInput{
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 5px;
    width:550px;

}
.doubleInput{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.radioBtns{
    width: 100%;
    display: flex;
    font-weight: bold;
    font-size: 12px;
}

.buttonNext{
    margin-top: 20px;
    left: 70%;
    top: 85%;
}


.buttonUpload{
    width: 134px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.money{
    font-weight: bold;
    font-size: 16px;
    font-family: 'Open Sans';
    width: 35px;
    margin: 0 auto;
    padding-top: 5px;

}
.moneyContainer{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 10%;

}


.arrowContainer{
    color:white;
    background-color: #285A64;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

}
.arrowIcon{

    display: inline-block;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}

.smallText{
    font-size: 12px;

}

.formRadio{
    -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     display: inline-block;

     background-color: #fff;
     color: #fff;
     height: 15px;
     width: 15px;
     border: 0.5px solid #ccc;
     border-radius: 50px;
     cursor: pointer;
     margin-right: 7px;
     outline: none;
}
.formRadio:checked::before
{

     font: 13px/1 'Open Sans', sans-serif;
     left: 11px;
     top: 7px;

}
.formRadio:hover
{
     background-color: #285A64;
}
.formRadio:checked
{
     background-color: #285A64;
}
label
{
     font: 15px/1.7 'Open Sans', sans-serif;
     color: 285A64;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     cursor: pointer;
}
.inputs{
    flex:1;
    margin-top:10px;
    color: black;
    width: 100%;
    outline: none;
    border: none;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    height: 33px;
    box-shadow: 0px 0px 4px 1px rgba(240,240,240,1);
}

.dropdown{
  flex:1;
  margin-top:5px;
  color: black;
  width: 100%;
  height: 33px;
  padding: 6px 10px;
  font: inherit;
}

.inputs::placeholder{
    color: #ccc;
}

.ButtonContainer{
  position:absolute;
  bottom: 0px;
  padding-bottom: 30px;
  width: 90%;
  display:flex;
  justify-content:flex-end;
}
