.Circle {
    width: 25px;
    height: 25px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius:  50%;
    background: #FFFFFF;
    position: absolute;
    top: 10px;
    left: calc(50% - 5px);
    border: 0.5px solid #cccccc;
    cursor: pointer;

  }

  .container{
    
   
    position: relative;
  }
.Active{
    background: #285A64;
    cursor: pointer;
} 

.Activated{
    background: #DAE3E4;
    cursor: pointer;
}

