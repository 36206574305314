@import "../modals.scss";

.modal_item{
  top: 55px;
  left: -46px;
  background: white;
  &::before {
    top: -30px;
    left: 35px;
  }
}

.new_modal_body{
  padding: 20px 40px 40px;
  white-space: nowrap;
}

.new_click_item{
  margin-top: 13px;
  font-size: 18px;
  font-family: 'Open Sans';
  letter-spacing: 0.7px;
  font-weight: bold;
}
