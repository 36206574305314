/* Account Selection*/
input{
  color: #285A64 !important;
  font-size: 15px !important;
}

.Component1{
    width: 700px;
    height: 439px;
    margin: 135px auto;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 0px 0px 40px 40px;
    background-color: white;
    justify-content: center;
    align-content: center;
}
.welcomeText{
    margin-top: 25px;
    height:160px;
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.header{
    height: 60px;
    background-color: #285A64;
}
.welcomeText1{
    text-align: center;
    font-family: 'Open Sans';
    font-weight: bold;
    letter-spacing: 0;
    color: #285A64;
    opacity: 1;
    font-size: 28px;
}

.welcomeText2{
    margin-top: 25px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0;
    color: #285A64;
    opacity: 1;
    font-size: 24px;
    font-family: 'Open Sans';

}
.accountTypeContainer{
    margin: 10px auto;
    width: 90%;
    height:120px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-content: center;
}

.accountType{
    height:100px;
    border-radius: 15px;
    background-color: #D39C3E;
    margin-right: 5px;
    margin-left: 5px;
    display: flex;
    cursor: pointer;
}
.accountTypeText{
    color:white;
    line-height: 1;
    font-size: 17px;
    font-weight: bold;
    font-family: 'Open Sans';
    text-align: center;
    margin: auto;
}
/* Register  */
.body{
    background-color: white;
}

.Register li{
    width: 54%;
    display:inline-block;
    padding: 0px;
    text-align: center;

}
.Components{
    margin: 50px 50px auto;
    position: absolute;
    width: 90%;
    display:inline-block;

}
.scroll{
    width: 5px;
}
.Component{
   overflow: hidden;
    width: 490px;
    text-align: center;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 0px 0px 40px 40px;
    position: absolute;

}

.Position0{
    left:5%;
    z-index: 7;
}

.Position1{
    left:15%;
    z-index: 6;
}

.Position2{
    left:25%;
    z-index: 5;
}

.Position3{
    left:35%;
    z-index: 4;
}

.Position4{
    left:45%;
    z-index: 3;
}

.Position5{
    left:55%;
    z-index: 2;
}

.Position6{
    left:65%;
    z-index: 1;
}

.Position7{
  left:67%;
  z-index: 1;
}

.Position8{
    left:76%;
    z-index: 0;
}

.Position9{
    left:40%;
    z-index: 0;
}

.Head{
    height: 60px;
    background: #285A64;
    margin: 0px;
    padding: 0px;
}

.Active{
    z-index:20;
    width: 490px;
    min-height: 720px;
    margin-top: -25px;
}
.Disabled{
    filter: blur(0.8px);
    height:600px;
}

.Final{
    z-index:100;
    width: 468px;
    height: 320px;
    top:100px;
}
.Header{
    height: 50px;
    border: 1px solid black;
}

.highlighted_input{
  border: 1px solid #ff7070!important;
}
