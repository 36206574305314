@import "../../../dashboard_style";
@import "../../CurrencyTrader.module.scss";

.container{
    max-height: 383px;
    width: 1020px;
    position: absolute;
    z-index: 5;
    top: 285px;
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50px;
    border: 1px solid #CCCCCC;
    background: white;
    overflow-y: auto;
    padding-top: 41.5px;
    padding-bottom: 41.5px;
}

.title{
  font-weight: bold;
  font-family: 'Open Sans';
  font-size: 35px;
  color: "#285A64";
  margin-left: 67px;
}

.bankComponentContainer{
  margin-left: 67px;
  margin-right: 67px;
  margin-top: 34px;
  display: flex;
  flex-wrap: wrap;
}

.bankComponent{
  margin-left: 90px;
  margin-bottom: 20px;
}

.bankComponent:nth-child(4n-3){
  margin-left: 0px;
}

.currencyItem{
  margin-top: 11.5px;
  margin-bottom: 11.5px;
}

.bank_name{
  font-weight: bold;
  font-family: 'Open Sans';
  font-size: 20px;
}

.currencyItem {
  font-size: 14px;
  font-family: 'Open Sans';
  span{
    font-weight: bold;
    margin-right: 11px;
  }
}

// ::-webkit-scrollbar {
//   display: none;
// }

.bank_icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.bank_title{
  display: flex;
  align-items: center;
}

.bigTitleContainer{
  display: flex;
  align-items:center;
  flex-direction:row;
}

.printer{
  font-size: 30px;
}
