.AddEmployeeContainer {
    width: 490px;
    margin: 0 auto;

    .PrivateAccountSlider {
        list-style-type: none;
        margin: 0 auto;
        padding: 0;
        width: 75%;
        height: 60px;
        align-content: center;
        justify-content: center;
        display:flex;
    }

    .hrPrivate{
        width: 490px;
        margin-top: 43px;
        border: 0.5px solid #cccccc;
        position: absolute;
    }

    .Header {
        height: 60px;
        background: #285A64;
        margin: 0px;
        padding: 0px;
        margin-top: 60px;
    }

    .FormContainer {
        height: 640px;
        padding: 30px 40px;
        border: 1px solid #ccc;
        background-color: white;
        border-radius: 0px 0px 40px 40px;

        .Form {

            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    input:focus {
        outline: none !important;
    }
}

.formRadio{
    -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     display: inline-block;
     background-color: #fff;
     color: #fff;
     height: 15px;
     width: 15px;
     border: 0.5px solid #ccc;
     border-radius: 50px;
     cursor: pointer;
     margin-right: 7px;
     outline: none;
}
.formRadio:checked::before
{

     font: 13px/1 'Open Sans', sans-serif;
     left: 11px;
     top: 7px;

}

.formRadio:checked
{
  background-color: #285A64;
}
label
{
     font: 15px/1.7 'Open Sans', sans-serif;
     color: 285A64;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     cursor: pointer;
}
