@import "../modals.scss";

.frequency_modal{
  top: 67px;
  width: 220px;
  left: -150px;
  &::before {
    top: -30px;
    left: 145px;
  }
}

.frequency_modal_body{
  padding: 20px 40px 40px;
}

.frequency_click_item{
  font-size: 18px;
  font-family: 'Open Sans';
  letter-spacing: 0;
  font-weight: bold;
}

.frequency_modal_header{
  padding-right: 38px;
}