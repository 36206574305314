.arrowContainer{
    color: white;
    background-color: #D39C3E;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

}
.arrowIcon{
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 18px;
    margin-left: 10px;
    cursor: pointer;
}
