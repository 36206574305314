@import "../modals.scss";

.personalizatons_modal{
    top: 67px;
    width: 347px;
    left: -272px;
    &::before {
      top: -30px;
      left: 260px;
    }
  }

  .personalizations_body{
    padding-top: 40px;
  }

  .checkbox_row{
    margin: 7px 0;
  }

  .actionButtons{
    width: 100%;
    padding-top: 30px;
    text-align: center;
  }
