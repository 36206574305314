
/* Register  */
.body{
    background-color: white;
}
 .Register{
    margin: 0 auto;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}
.Register li{
    width: 58%;
    display:inline-block;
    padding: 0px;
    text-align: center;

}
.Components{
    margin: 50px 11% auto;
    position: absolute;
    width: 85%;
    display:inline-block;

}
.scroll{
    width: 5px;

}
.Component{
    overflow: hidden;
    width: 490px;
    text-align: center;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 0px 0px 40px 40px;
    position: absolute;

}

.Position0{
    left:10%;
    z-index: 7;
}

.Position1{
    left:30%;
    z-index: 6;
}

.Position2{
    left:40%;
    z-index: 5;
}

.Position3{
    left:50%;
    z-index: 4;
}

.Position4{
    left:35%;
    z-index: 3;
}


.Head{
    height: 60px;
    background: #285A64;
    margin: 0px;
    padding: 0px;
}

.Active{
    z-index:20;
    width: 490px;
    min-height: 720px;
    margin-top: -25px;
    overflow-y: auto;
}
.Disabled{
    filter: blur(0.8px);
    height:600px;
}

.Final{
    z-index:100;
    width: 468px;
    height: 320px;
    top:100px;
}
.Header{
    height: 50px;
    border: 1px solid black;
}

.container{
    margin: 30px auto;
    width: 90%;
    height: 60px;
    align-content: center;
    justify-content: center;
    display:flex;
}
.container,.MarketViewerSlider, .PrivateAccountSlider
    li{
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight:bold;

    }
    .PrivateAccountSlider{
        margin: 30px auto;
        width: 75%;
        height: 60px;
        align-content: center;
        justify-content: center;
        display:flex;
    }
    .hrPrivate{
        width: 85%;
        margin-top: 43px;
        border: 0.5px solid #cccccc;
        position: absolute;
    }
    .MarketViewerSlider{
        margin: 30px auto;
        width: 55%;
        height: 60px;
        align-content: center;
        justify-content: center;
        display:flex;

    }
    .hr{
        width: 65%;
        margin-top: 43px;
        border: 0.5px solid #cccccc;
        position: absolute;
      }
