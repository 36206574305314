.FirstFloor{
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 0px 0px 40px 40px;

}

.Disabled{
    width: 0%;
    height: 0%;
    background-color: black;
}

.Active{
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: inherit;
}


.Activated{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    border-radius: 0px 0px 40px 40px;
    z-index: inherit;

}
