@import "../modals.scss";

input[type="checkbox"]{
  position: relative;
  height: 15px;
  width: 15px;
  -webkit-appearance: none;
  background: transparent;
  outline: none;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
  transition: .5s;
  cursor: pointer;
}
input[type="checkbox"]:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 20px;
    top: 12%;
    left: 0;
    background: white;
    transform: scale(1.1);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
    transition: .5s;
    border: 1px solid #cccccc;
}
input:checked[type="checkbox"]:before {
  left: 0px;
  background: #285A64;
}
.checkbox_row{
  span{
    margin-left: 5px;
    font-weight: bold;
    font-size: 13px;
  }
}

.modal_item{
  top: 33px;
  left: -40px;
  width: 341px;
  background: white;
  &::before {
    top: -30px;
    left: 29px;
  }
}

.filter_modal_body{
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 20px 40px 40px;
}

.filter_modal_header{
    padding-right: 215px;
  }

  .newTransaction{
    color:white;
    margin-top: 15px;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Open Sans';
    margin-left: 10px;
  }
  .cancelButton{
    background: white;
    color: #D39C3E;
    border: 1px solid #D39C3E;
    margin-left: 15px;
  }
  .buttons_center{
    display: flex;
    justify-content: center;
    margin: 20px 10px;
  }

  .itemHeader{
      background: #285A64;
      height: 35px;
      width: 100%;
      display: flex;
      align-items: center;

  }
  .itemTitle{
      color: white;
      font-size: 15px;
      font-weight: bold;
      margin-top: 15px;
      margin-left: 10px;
  }
  .currencies{
      display: flex;
      justify-content: center;
  }
  .currency{
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      width: 32px;
      height: 32px;
      margin: 10px 5px;

      padding: 5px;
      font-weight: bold;
      font-size: 20px;
      border-radius: 50%;
      border: 1px solid #285A64;
      cursor: pointer;
  }
  .inputs{
    margin: 20px 0px;
    color: #070707;
    width: 100%;
    outline: none;
    border: 0.5px solid #CCCCCC;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    height: 33px;
    box-shadow: 0px 0px 4px 1px rgba(240,240,240,1);
  }

  .left_input{
    margin-right: 5px;
  }

  .inputs::placeholder{
      color: #ccc;
      text-align: end;
  }
  .radioBtns{
    display: flex;
    font-weight: bold;
    color: #285A64;
    font-size: 22px;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    width: 140px ;
    margin: 0 auto
  }
  .formRadio{
    -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     display: inline-block;
     background-color: #fff;
     color: #fff;
     height: 15px;
     width: 15px;
     border: 0.5px solid #285A64;
     border-radius: 50px;
     cursor: pointer;
     margin-right: 7px;
     outline: none;

  }
  .formRadio:checked::before
  {

     font: 13px/1 'Open Sans', sans-serif;
     left: 11px;
     top: 7px;

  }
  .formRadio:hover
  {
     background-color: #285A64;
  }
  .formRadio:checked
  {
     background-color: #285A64;
  }
  .normalText{
      font-size: 12px;
  }

  .arrowItem {
    color: rgb(211, 156, 62);
    font-size: 35px;
    margin-right:10px;
    position:absolute;
    left:-32px;
  }
