
.firstTitle{
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
}


.container{
    width: 100%;
    flex-direction: column;
}
.firstForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
}
.singleInput{
    width: 85%;
}
.doubleInput{
    width: 85%;
    display: flex;
    justify-content: space-between;
}
.secondForm{
    margin-top:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;

}


.inputs{
    margin-top:10px;
    color: black;
    width: 100%;
    outline: none;
    border: none;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    height: 33px;
    box-shadow: 0px 0px 4px 1px rgba(240,240,240,1);
}
.inputs::placeholder{
    color: #ccc;
}

.ButtonContainer{
  position:absolute;
  bottom: 0px;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 90%;
  display:flex;
  justify-content:flex-end;
}
