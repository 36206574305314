

.ThankYouText{   
        text-align: center;
        font-family: 'Open Sans';
        font-weight: bold;
        letter-spacing: 0;
        color: #285A64;
        opacity: 1;
        font-size: 28px;
}
.firstForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding: 5px;
}

.firstTitle{
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: bold;

}
.someText{
    font-size: 14px;
}
.formRadio{
    -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     display: inline-block;
     background-color: #fff;
     color: #fff;
     height: 15px;
     width: 15px;
     border: 0.5px solid #ccc;
     border-radius: 50px;
     cursor: pointer;     
     margin-right: 7px;
     outline: none; 
}
.formRadio:checked::before
{
    
     font: 13px/1 'Open Sans', sans-serif;
     left: 11px;
     top: 7px;
    
}
.formRadio:hover
{
     background-color: #285A64;
}
.formRadio:checked
{
     background-color: #285A64;
}
label
{
     font: 15px/1.7 'Open Sans', sans-serif;
     color: 285A64;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     cursor: pointer;
} 
