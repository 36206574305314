@import '../../../assets/scss/variables';

#modal_my_profile{
  position: fixed;
  top: 55px;
  left: 0;
  background: #ffffffc8;
  width: 100%;
  height: calc(100vh - 55px);
  display: flex;
  justify-content: center;
  z-index: 100;
  overflow-y: scroll;
  #Modal_hide{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    top:0;
  }
  .modal_container{
    width: 767px;
    position: relative;
    top: 20px;
  }
  .modal_header{
    background: #285A64;
    display: flex;
    padding: 18px 15px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    font: Bold 25px/29px Lato;
    color: white;
  }
  .modal_body{
    background: white;
    padding: 30px 60px;
    border: solid #cccccc 1px;
    border-radius: 0px 0px 30px 30px;
  }
}
/* Icon Container */

.icon_container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.icon_item{
  margin-left: 15px;
  margin-right: 15px;
}
.icon_item i{
  font-size: 58px;
  color: #D39C3E;
  cursor: pointer;
}

/* information_header */
.information_header{
  background: #285A64;
  color: white;
  display: flex;
  padding: 12px 18px;
  align-items: center;
  height: 50px;
}
.ih_title{
  flex: 1;
  text-align: center;
  font: Bold 24px/24px Open Sans;
}
.ih_edit{
  font-size: 24px;
  cursor: pointer;
}


.info_subheader{
  font: Bold 24px/9px Open Sans;
  height: 24px;
}
.info_subheader_mini{
  font-size: 12px;
}

/* Mini Info */
.info_card{
  width: 100%;
  border-radius: 24px;
  border:solid 0.5px #CCCCCC;
  padding: 30px;
  margin-bottom: 21px;
  cursor: pointer;

}
.info_card_header{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
.info_card_header_title{
  font: Bold 20px/9px Open Sans;
  margin-right: 5px;
  height:20px;
  display: flex;
  align-items: center;
}
.info_card_header_icon{
  cursor: pointer;
  font-size: 20px;
  color: #D39C3E;
}
.info_card_flex_between{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  b{
    width: 60px;
    margin-left: 20px;
    text-align: left;
  }
  span{
    text-align: left;
    margin-left: 10px;
  }
}
.info_card_subheader_v1{
  font: Bold 20px/9px Open Sans;
  height: 20px;
  line-height: 20px;
  margin-bottom: 10px;
}

.info_card_text{
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 7px;
}
.info_card_input{
  width: 100%;
  border: solid 1px #eee;
  color: #285A64;
  height: 32px;
  margin-bottom: 5px;
}
.info_card_input::placeholder {
  color: #ccc;
  padding-left: 10px;
  font-size: 14px;
}
.info_card_row {
  div[class^="col-"]{
    padding: 5px;
  }
  input {
    text-align: left;
    font: 14px/8px Open Sans;
    letter-spacing: 0;
    color: #285A64;
    padding: 5px;
  }
}


/* SHAREHOLDER BUTTON */
.CEO_Details{
  width: 100%;
  text-align: center;
  font-size: 17px;
  padding-bottom: 40px;
  margin: 0 auto;
  align-items: center;
  font: 15px Open Sans;
}
.CEO_other_details {
  padding-top: 40px;
  margin: 0 auto;
  font: 15px Open Sans;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.info_between{
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  font: 15px Open Sans;
  span{
    float: right;
  }
}
// vertical border infos
.vertical_info {
  width: 60%;
  margin: auto;
  margin-left: 8%;
  text-align: left ;
  font: 15px Open Sans;
  span{
    float: right;
  }
}
.borderLeft {
  display: grid;
  border-left: 4px solid #bfbfbf;
  height: 100px;
  margin-left: 15%;
  margin-bottom: 10%;
}


// Button Type 1
@mixin buttonType {
  border: 1px solid #D39C3E;
  border-radius: 12px;
  text-align: center;
  font: Bold 16px/19px Open Sans;
  letter-spacing: 0;
  display: inline-block;
  padding: 9px 17px;
  cursor: pointer;
  margin-left: 6px;
  margin-right: 6px;
}
.button_type1{
  @include buttonType;
  background: #D39C3E;
  color: #FFFFFF;
}
.button_type2{
  @include buttonType;
  background: white;
  color: #D39C3E;
}

// Saved Container
.SavedContainer{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffffd4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.SavedContainerText{
  text-align: center;
  font: Bold 24px/30px Open Sans;
  letter-spacing: 0;
  color: #285A64;
}
.SavedContainerIcon{
  font-size: 150px;
}

// CheckBox container
.checkBox_container{
  .checkBox_container_row{
    display: flex;
    align-items: center;
    position: relative;
    height: 24px;
    .checkBox_icon_side{
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .checkBox_text_side{
      padding-left: 5px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
.checkBox_design{
  margin: 4px;
  width: 16px;
  height: 16px;
  position: relative;
  input[type="checkbox"]{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    z-index: 9999;
  }
  .round_design{
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: solid #285A64 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .round_design::after{
    content: '';
    height: 16px;
    width: 16px;
    background: #285A64;
    border-radius: 50%;
    transition: 200ms ease-in-out 0s;
    opacity: 0;
    border: solid 8px;
  }
  input[type="checkbox"]:checked ~ .round_design::after{
    opacity: 1;
  }
}



// Radio container
.radio_container{
  .radio_container_row{
    display: flex;
    align-items: center;
    position: relative;
    height: 24px;
    .radio_icon_side{
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .radio_text_side{
      padding-left: 5px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
.radio_design{
  margin: 4px;
  width: 16px;
  height: 16px;
  position: relative;
  input[type="radio"]{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    z-index: 9999;
  }
  .round_design{
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: solid #285A64 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .round_design::after{
    content: '';
    height: 16px;
    width: 16px;
    background: #285A64;
    border-radius: 50%;
    transition: 200ms ease-in-out 0s;
    opacity: 0;
    border: solid 8px;
  }
  input[type="radio"]:checked ~ .round_design::after{
    opacity: 1;
  }
}


//
.hr_choose_row{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  .hr_choose_item{
    font-size: 20px;
    font-weight: bold;
    color: #D39C3E;
    opacity: 0.28;
    cursor: pointer;
  }

  .hr_choose_seperator{
    width: 2px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    background: #D39C3E;
    opacity: 0.28;
  }

}
.hr_note_text_mini{
  text-align: center;
  font-size: 10px;
}


.icon_cirlce_type1{
  width: 24px;
  height: 24px;
  background: #D39C3E;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  i{
    color: white;
    font-size: 16px;
  }
}

.icon_cirlce_type2{
  width: 14px;
  height: 14px;
  display: inline-block;
  background: #285A64;
  border-radius: 50%;
  cursor: pointer;
  i{
    color: white;
    font-size: 10px;
  }
}

.helpMiniText{
  font-size: 10px;
  font-weight: bold;
  margin-top: 15px;
}




// Before for primary_address
.primary_little_round::before{
  position: absolute;
  content: '';
  top: 9px;
  left: 5px;
  width: 5px;
  height: 5px;
  background: #285A64;
  border-radius: 50%;
}

// Text inside payments account
.pa_text_t1{
  font-size: 14px;
}


.primary_text_s1{
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}
.primary_text_s2{
  margin-left: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
