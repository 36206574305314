@import "../dashboard_style";
@import "../../Modals/modals.scss";

.dashboardItem{
  margin-top: 27px;
}

/* My Dossier */

.CurrencyTrader{
    width:100%;
    border: 0.5px solid #CCCCCC;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    height:auto;
    margin-top:30px;
    flex-direction: column;
    display: flex;
    background-color: white;
  }
  .CurrencyTraderClosed{
    width:100%;
    height:50px;
    margin-top:30px;
    background: white;
    overflow: hidden;
  }
  .CurrencyTraderText{
    color:white;
    margin-top: 15px;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Open Sans';
  }
  .dossierHeader{
    height:50px;
    background-color: #285A64;
    flex-direction: row;
    width: 92%;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // cursor: pointer;
  }
  .dossierLeftIcons{
    display: flex;
    align-items: center;
    color:white;
    margin-left: 20px;
  }
  .dossierRightIcons{
    display: flex;
    align-items: center;
    color:white;
    margin-right: 20px;
  }
  .dossierToolbar{
    display:flex;
    width:100%;
  }
  .toolbarElement{
    border: 0.5px solid #CCCCCC;
    color:#285A64;
    padding:10px;
    width:34%;
    justify-content: center;
    display: flex;
  }
  .toolbarElementClicked{
    border: 0.5px solid #CCCCCC;
    border-bottom:none;
    color:#285A64;
    padding:10px;
    width:33%;
    justify-content: center;
    display: flex;
  }


  /* Dossier Modal */

  .dossierModal{
    top: 67px;
    width: 560px;
    max-width: 92vw;
    left: -185px;
    &::before {
      top: -30px;
      left: 220px;
    }
}

.checkoutModal{
  top: 67px;
  width: 560px;
  max-width: 92vw;
  left: -157px;
  &::before {
    top: -30px;
    left: 149px;
  }
}

.dossierBody{
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 20px 40px 40px;
}

.dossierModalHeaderIcon{
  width: 23px;
}

.newTransaction{
  color:white;
  margin-top: 15px;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Open Sans';
  margin-left: 10px;
}

.someText{
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}
.cancelButton{
  background: white;
  color: #D39C3E;
  border: 1px solid #D39C3E;
  margin-left: 15px;
}
.options{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  line-height: 1;
}
.optionText{
  color: #D39C3E;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
}
.currenciesHeader{
  width: 100%;
  height: 40px;
  background: #285A64;
}
  .checkoutHeaderBuy{
    background: #cccccc;
    width: 100%;
    height: 40px;
  }

  .checkoutHeaderSell{
    background: rgba(204, 204, 204, 0.28);
    width: 100%;
    height: 40px;
  }

  .checkoutHeader{
    background: #cccccc;
    width: 100%;
    height: 40px;
  }

  .checkoutCurrencies{
    background: rgba(204,204,204, 0.28);
    width: 100%;
    height: 100px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


.currencies{
  background: #DAE2E4;
  width: 100%;
  height: 100px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.currenciesDropdown{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(204,204,204, 0.28);
    width: 100%;
    height: 100px;
    margin-top: 5px;
}

.sellHeader{
  width: 100%;
  height: 40px;
  background: #BFD2C7;
}

.sellCurrencies{
  background: #F3F7F5;
  width: 100%;
  height: 100px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioBtns{
  display: flex;
  font-weight: bold;
  color: #285A64;
  font-size: 22px;
  height: 90px;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.radioBlock{
  display: flex;
  align-items: baseline;
justify-content: center;
padding: 10px;

}
.currencyContainer{
  display: flex;
  flex-direction: column;
  line-height: 0;
}
.bigText{
  font-size: 22px;
}
.smallText{
  display: inline;

}
.formRadio{
  -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   display: inline-block;
   background-color: #fff;
   color: #fff;
   height: 15px;
   width: 15px;
   border: 0.5px solid #285A64;
   border-radius: 50px;
   cursor: pointer;
   margin-right: 7px;
   outline: none;

}
.formRadio:checked::before
{

   font: 13px/1 'Open Sans', sans-serif;
   left: 11px;
   top: 7px;

}
.formRadio:hover
{
   background-color: #285A64;
}
.formRadio:checked
{
   background-color: #285A64;
}
label
{
   font: 15px/1.7 'Open Sans', sans-serif;
   color: 285A64;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   cursor: pointer;
}

.dashboardHeader:hover{
  cursor: pointer;
}

.otherCurrencies{
  display: flex;
  flex-direction: column;
}

.order_pagination{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.numbers{
  margin-right: 5px;
  cursor: pointer;
  font-size: 15px;
  color: #D39C3E;
  font-weight: bold;
}

.trade_button{
  background: #D39C3E;
  width: 100px;
  height: 32px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: bold;
  color: white
}
