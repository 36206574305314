/* New Correct Design */
.dashboardItem{
  width:100%;
  border: 0.5px solid #CCCCCC;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  flex-direction: row;
  background-color: white;
}
.dashboardHeader{
  height:50px;
  width: 100%;
  background-color: #285A64;
  padding: 15px;
  box-sizing: border-box;
  border: 0.25px solid #CCCCCC;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  &LeftSide{
    flex: 1;
    display: flex;
    align-items: center;
  }
  &RightSide{
    display: flex;
    align-items: center;
  }
  &Title{
    font-size: 18px;
    font-weight: bold;
    font-family: 'Open Sans';
    color: white;
    display: flex;
    align-items: center;
  }
  & i{
    position: relative;
    cursor: pointer;
    font-size:23px;
    color: white;
    margin-left: 10px;
  }
}

.summary_title{
  font-size: 32px;
  font-weight: bold;
  font-family: 'Open Sans';
  color: #285A64;
  text-align: center;
  display: inline-block;
  padding-bottom: 10px;
  span{
    font-size: 32px;
  }
  span.summary_online{
    color: #649177;
  }
  span.summary_store{
    color: #992020;
  }
}

/* Currency Box Design */
.currencyBox{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.currencyLeftPart{
  flex: 45;
  display: flex;
  justify-content: flex-end;
}
.currencyRightPart{
  flex: 55;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
}
.currencyTypeText{
  font-family: 'Open Sans';
  font-weight: Bold;
  font-size: 32px;
  line-height: 32px;
  text-align: left;
  letter-spacing: 0;
  color: #285A64;
  height: 32px;
  width: 80px;
  display: flex;
  align-items: center;
}
.currencyMoney{
  font-size: 32px;
  line-height: 30px;
  text-align: left;
  font-weight: 600;
  color: #285A64;
  display: flex;
  flex-direction: column;
}
.currencyReservedAmount{
  font-size: 16px;
  line-height: 24px;
  color:#285A64;
  font-weight: bold;
  margin-left: 5px;
}
.currentExtraSeperator{
  height: 1px;
  background: #cccccc;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 70%;
}
.currentExtraText{
  position: relative;
  text-align: 16px;
}
.ShareSquare{
  position: absolute;
  top: 0;
  left: -45px;
  height: 100%;
  display: flex;
  align-items: center;
  color:#D39C3E;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer
}

.infoIcon{
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
  cursor:pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #D39C3E;
  position: relative;
}

.infoIcon.closedIcon{
  background: #D39C3E;
}
.infoIcon.openIcon{
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoIcon.openIcon i {
  color:#D39C3E;
}

/* hr-seperator */
.hr_seperator{
  height: 1px;
  background: #cccccc;
  width: 100%;
}

.SummaryBottomTextContainer{
  text-align: center;
  letter-spacing: 0;
  margin-top: 20px;
  font-size: 16px;
  font-family: 'Open Sans';
  font-weight: bold;
  span.coloredRed{
    color: #992020;
  }
}

// Order
.order_container{
  padding: 55px 80px;
}
.order_card_container{
  position: relative;
  width: 100%;
  border: 0.5px solid #CCCCCC;
  border-radius: 0 24px 24px 0;
  margin-top: 10px;
  .order_card_block{
    width: 25px;
  }
  &.registered{
    .order_card_block{
      background: #cccccc;
    }
    .order_card_logo{
      background: #cccccc;
      color: white;
      text-align: center;
      font-weight: bold;
    }
  }
  &.purchases_posted{
    .order_card_block{
      background: #285A64;
    }
    .order_card_logo{
      background: #285A64;
      color: white;
      text-align: center;
      font-weight: bold;
    }
  }
  &.purchases_purchased{
    .order_card_block{
      background: #DAE2E4;
    }
    .order_card_logo{
      background: #DAE2E4;
      color: white;
      text-align: center;
      font-weight: bold;
    }
  }
  &.sales_posted{
    .order_card_block{
      background: #BFD2C7;
    }
    .order_card_logo{
      background: #BFD2C7;
    }
  }
  &.sales_sold{
    .order_card_block{
      background: #e1ece7;
    }
    .order_card_logo{
      background: #e1ece7;
      color: white;
      text-align: center;
      font-weight: bold;
    }
  }
  &.purchases_cm{
    .order_card_block{
      background: #cccccc;
    }
    .order_card_logo{
      background: #cccccc;
      color: white;
      text-align: center;
      font-weight: bold;
    }
  }
  &.sales_cm{
    .order_card_block{
      background: rgba(204, 204, 204, 0.28);
    }
    .order_card_logo{
      background: rgba(204, 204, 204, 0.28);
      color: white;
      text-align: center;
      font-weight: bold;
    }
  }
}
.order_card_header{
  display: flex;
  cursor: pointer;
}

.order_card_details{
  position: relative;
  padding: 20px 34px 15px 10px;
  flex:1;
}
.order_card_details_row{
  display: flex;
  align-items: center;
}
.order_card_details_left{
  flex:1;
  display: inline-flex;
}
.order_card_logo{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  background: #cccccc;
  color: white;
  text-align: center;
  font-weight: bold;
}

.order_card_type{
  font-weight: bold;
  padding-left: 7px;
}

.order_card_user{
  font-weight: bold;
  padding-left: 7px;
  font-size: 12px;
  align-self: center;
}

.order_card_button{
  align-self: center;
  padding: 0 23px;
}

.order_card_small_text{
  font-size: 12px;
  font: Bold 12px/15px Open Sans;
}
.order_card_bold_text{
  font-size: 20px;
  font: Bold 20px/15px Open Sans;
}
.order_card_details_twoColumns{
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
}
.order_card_details_leftColumn{
  flex:1;
  padding-right: 50px;
}
.order_card_details_rightColumn{
  flex:1;
}
.order_card_extra{
  padding-top: 20px;
  padding-bottom: 20px;
}
.order_card_extra_bold_text{
  font-size: 14px;
  font: Bold 14px/20px Open Sans;
  height: 20px;
}
.order_card_extra_regular_text{
  font-size: 14px;
  font: Regular 14px/20px Open Sans;
}
.order_card_borderBottom{
  border-bottom: solid 1px #CCCCCC;
  margin-bottom: 15px;
}
.order_card_expireText{
  color: #9A2020;
  font: Bold 14px/20px Open Sans;
  display: inline;
}

.activePage{
  color: #a27930 !important;
  font-size: 20px !important;
}

.formRadio{
  -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   display: inline-block;
   background-color: #fff;
   color: #fff;
   height: 15px;
   width: 15px;
   border: 0.5px solid rgb(211, 156, 62);
   border-radius: 50px;
   cursor: pointer;
   margin-right: 7px;
   outline: none;
}
.formRadio:checked::before
{
   font: 13px/1 'Open Sans', sans-serif;
   left: 11px;
   top: 7px;
}

.formRadio:hover
{
   background-color: rgb(211, 156, 62);
}
.formRadio:checked
{
   background-color: rgb(211, 156, 62);
}
