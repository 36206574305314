@import "../dashboard_style";

.currencyBox{
  margin-bottom: 40px;
}

.order_pagination{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.numbers{
  margin-right: 5px;
  cursor: pointer;
  font-size: 15px;
  color: #D39C3E;
  font-weight: bold;
}

a:hover{
    color: #D39C3E;
}

.daily_settings_card_small_text{
  font-size: 16px;
  font: Open Sans;
  font-weight: bold;
}

.daily_settings_extra_small_text{
  font-size: 9px;
  margin-right: 15px;
}

.daily_settings_big_text{
  font-size: 25px;
}

.daily_settings_button{
  width: 30%;
  margin-top: 20px;
}

.dashboardHeader:hover{
  cursor: pointer;
}
