/* Buy Trasaction */
.newRegistration{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  // justify-content: start;
    align-items: center;
}

.Component{
    margin:35px auto;
    // margin:35px 25px;
    width: 600px;
    max-width: 92vw;
    height: auto;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 0px 0px 40px 40px;
    background: white;
}

.button_disabled{
  opacity:0.5
}

.graphComponent{
  margin: 110px 0px 0px 45px;
  width: 571px;
  height: 492px;
  border: 1px solid #ccc;
  border-radius: 0px 0px 40px 40px;
  background: white;
}

.offersComponent{
  margin: 110px 45px 0px 0px;
  width: 571px;
  display: flex;
  flex-flow: column;
  border: 1px solid #ccc;
  border-radius: 0px 0px 40px 40px;
  background: white;
}

.offersAmount{
  text-align: center;
  color: #285A64;
  font-size: 33px;
  font-family: Lato;
  font-weight: bold;
  padding: 15px 0px 0px;
}

hr {
  border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: #dae2e4;
    height: 1px;
}

.offer{
  text-align: center;
  color: #285A64;
  font-family: Lato;
  padding: 14px 15px;
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
}

.offerEmpty{
  text-align: center;
  color: #285A64;
  font-family: Lato;
  padding: 24px 15px;
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
}

.empty{
  margin-right: 35px;
  font-size: 24px;
}

.offerDetails{
  font-size: 21px;
  font-weight: bold;
}

.offerDetailsSmallBold{
  font-size: 15px;
  font-weight: bold;
  text-align: initial;
  flex:1;
}

.offerDetailsMediumBold{
  font-size: 20px;
  font-weight: bold;
  text-align: initial;
  flex: 1;
}

.offerDetailsSmallText{
  font-size: 19px;
  width: 50%;
  margin-left: 107px;
}

.onlineOfferDetailContainer {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.Head{
    display: flex;
    height: 55px;
    background: #285A64;
    margin: 0px;
    padding: 0px;
    justify-content: space-between;
    align-items: center;
}
.TransactionText{
    color:white;
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Open Sans';
  }

  .currenciesHeader{
    width: 100%;
    height: 40px;
    background: #285A64;
  }
  .checkoutHeaderBuy{
    background: #cccccc;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content:center;
    cursor: pointer;
  }
  .checkoutHeaderSell{
    background: rgba(204, 204, 204, 0.28);
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content:center;
    cursor: pointer;
  }
  .currencies{
    width: 100%;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .checkoutCurrencies{
    background: rgba(204,204,204, 0.28);
    width: 100%;
    height: 120px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .checkoutCurrenciesContent{
    height: 60px;
    line-height: 0.5;
  }
  .container{
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 20px 40px 40px;
  }

  .graphContainer{
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 20px 20px 0px;
  }

  .containerSell{
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 30px 30px;
  }
  .currencies1{
    width: 100%;
    height: 150px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .currencies2{
    width: 100%;
    height: 140px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Sale{
    background: #f3f7f5;
  }

  .Purchase{
    background: #DAE2E4;
  }

.fixedOrder{
    width: 100%;
    height: 200px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  .someText{
    font-weight: bold;
    text-align: center;
    font-size: 16px;
  }
  .options{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 10px;
    // line-height: 0.5;
    line-height: 120%;
  }
  .optionText{
    color: #D39C3E;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
  }
  .boldText{
      font-size: 24px;
      font-weight: bold;
      height:25px;
  }
  .lightGreenText{
      color: #659278;
      font-size: 24px;
      font-weight: bold;
  }
  .cancelButton{
    background: white;
    color: #D39C3E;
    border: 1px solid #D39C3E;
    margin-left: 15px;
  }

  .sessionButton{
    width: initial;
  }

  .buttons{
    display: flex;
    justify-content: flex-end;
    margin: 20px 10px;
  }
  .buttons_center{
    display: flex;
    justify-content: center;
    margin: 20px 10px;
  }
  .left_part{
   display: flex;
   align-items: baseline;
   width: 80px;
  }
  .right_part{
    display: flex;
    align-items: baseline;
    width: 250px;
    justify-content: center;
  }
  .top_part{
    display: flex;
    //justify-content: space-between;
    align-items: baseline;
    width: 340px;
  }
  .bottom_part{
    display: flex;
    //justify-content: space-between;
    align-items: baseline;
    width: 345px;
  }
.currencies_content{
  // line-height: 0.5;
  line-height: 110%;
}
  .inputs{
    margin-top:10px;
    margin-bottom: 7px;
    color: #070707;
    width: 100%;
    outline: none;
    border: none;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    height: 33px;
    box-shadow: 0px 0px 4px 1px rgba(240,240,240,1);
  }

  .inputDate{
    margin-top:10px;
    margin-bottom: 7px;
    color: #070707;
    outline: none;
    border: none;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    height: 33px;
    width: 130px;
    box-shadow: 0px 0px 4px 1px rgba(240,240,240,1);
  }
.inputTime{
  margin-top:10px;
  margin-bottom: 7px;
  margin-left: 10px;
  color: #070707;
  outline: none;
  border: none;
  background-color: white;
  font: inherit;
  padding: 6px 10px;
  height: 33px;
  width: 80px;
  box-shadow: 0px 0px 4px 1px rgba(240,240,240,1);
}

.inputDate::placeholder, .inputTime::placeholder{
  text-align: center;
  color: #ccc;
}
  .inputs::placeholder{
     text-align: start;
      color: #ccc;
  }
  .formRadio{
    -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     display: inline-block;

     background-color: #fff;
     color: #fff;
     height: 15px;
     width: 15px;
     border: 0.5px solid #ccc;
     border-radius: 50px;
     cursor: pointer;
     margin-right: 7px;
     outline: none;
}
.formRadio:checked::before
{

     font: 13px/1 'Open Sans', sans-serif;
     left: 11px;
     top: 7px;

}

.formRadio:checked
{
     background-color: #285A64;
}
label
{
     font: 15px/1.7 'Open Sans', sans-serif;
     color: 285A64;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     cursor: pointer;
}

  .span{
    color: #659278;
  }

/* Toggle Switch */
.toggleSwitch{
 margin-right: 10px;

 input[type="checkbox"]{
  position: relative;
  width: 35px;
  height: 20px;
  -webkit-appearance: none;
  background: transparent;
  outline: none;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
  transition: .5s;
  cursor: pointer;
}

input:checked[type="checkbox"]
{
background: white;
}

input[type="checkbox"]:before
{
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 20px;
  top: 12%;
  left: 5%;
  background: white;
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: .5s;
}
input:checked[type="checkbox"]:before
{
left: 17px;
background: #285A64;
}

}
.switchText{
  color: #659278;
  text-align: end;
  padding-top: 10px;
  padding-right: 15px;

}

/* Sell Trasaction */
.currenciesHeaderSell{
  width: 100%;
  height: 40px;
  background: #BFD2C7;
}
.currenciesSell{
  background: #F3F7F5;
  width: 100%;
  height: 130px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detailsWrapper {
    width: 100%;
    text-align: -webkit-right;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.currencies1Sell{
  background: #F3F7F5;
  width: 100%;
  height: 150px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.currencies2Sell{
  background: #F3F7F5;
  width: 100%;
  height: 140px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fixedOrderSell{
    background: #F3F7F5;
    width: 100%;
    height: 200px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

 .Progress{
  animation: cssAnimation1 0s 2s forwards;
  visibility: visible;

}
@keyframes cssAnimation1{
  to   { visibility: hidden; }
}

.ComponentBestOffer{
  // position: absolute;
  top:6%;
  // left: 34.5%;
}

@keyframes cssAnimation {
  to   { visibility: visible; }
}

.radioBtns{
  width: 85%;
  display: flex;
  font-weight: bold;
  font-size: 12px;
}
.firstForm{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}
.singleInput{
  width: 85%;

}
.doubleInput{
  width: 85%;
  display: flex;
  justify-content: space-between;
}
.secondForm{
  margin-top:10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}

.searchBar{
  width: 85%;
  border: 0.5px solid #CCCCCC;
  height: 33px;
  display: flex;

}
.subtitle{
  font-size: 25px;
  font-weight: 600;
  margin: 20px;
}

.input_requested_small {
  text-align: left;
  padding-left: 7px;
  margin-top: 10px;
  font-size: 13px;
}

.recap_input_container {
  display:flex;
  flex-direction:row;
  margin-top:20px;
  align-items:center;
}
