.Introduction h1{
    font-family: 'Lato', sans-serif;
    font-size: 70px;
    margin: 0;
    padding: 0;

}

.Introduction h2{
    font-family: 'IBM Plex Sans', sans-serif;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0;
}

.Text{
    position: absolute;
    left: 20%;
    right: 15%;
    top:10%;
    float: right;
    color:#285A64;
}

p {
    font-size: 13px;
}

.Introduction img{
    position: absolute;
    top:10%;
    left: 10%;
    float: left;
    padding: 5px;
    width: 150px;

}
