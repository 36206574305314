.firstTitle{
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
}


.container{
    width: 100%;
    flex-direction: column;
}
.firstForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
}
.secondForm{
    margin-top:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
}
.singleInput{
    width: 85%;
}
.doubleInput{
    width: 85%;
    display: flex;
    justify-content: space-between;
}
.radioBtns{
    width: 85%;
    display: flex;
    font-weight: bold;
    font-size: 12px;
    justify-content: center

}

.buttonNext{
    margin-top: 20px;
    left: 70%;
    top: 85%;
}

.arrowContainer{
    background-color: white;
    color: #D39C3E;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 5px;
}
.buttonUpload{
    width: 134px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formRadio{
    -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     display: inline-block;

     background-color: #fff;
     color: #fff;
     height: 15px;
     width: 15px;
     border: 0.5px solid #ccc;
     border-radius: 50px;
     cursor: pointer;
     margin-right: 7px;
     outline: none;
}
.formRadio:checked::before
{

     font: 13px/1 'Open Sans', sans-serif;
     left: 11px;
     top: 7px;

}
.formRadio:hover
{
     background-color: #285A64;
}
.formRadio:checked
{
     background-color: #285A64;
}
label
{
     font: 15px/1.7 'Open Sans', sans-serif;
     color: 285A64;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     cursor: pointer;
}

.inputs{
    margin-top:10px;
    color: black;
    width: 100%;
    outline: none;
    border: none;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    height: 33px;
    box-shadow: 0px 0px 4px 1px rgba(240,240,240,1);
}
.inputs::placeholder{
    color: #ccc;
}
