@import "../dashboard_style";

.dashboardItemBody{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    margin: 23px 0;
}

.card{
    box-shadow: 0px 2px 3px #00000029;
    border: 0.5px solid #CCCCCC;
    border-radius: 24px;
    opacity: 1;
    padding: 10px;
    margin: 5px 0;
}

.cardTitle{
    text-align: center;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 30px;
}

.leftButton{
    border-radius: 15px 0 0 15px;
    background-color: #649177;
    color: white;
    font-family: "Open Sans";
    font-weight: bold;
    width: 90px;
    height: 41px;
    border-radius: 15px;
}

.leftButton:hover{
    background: #D39C3E;    
    color: white;
}

.rightButton{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #9A2020;
    color: white;
    font-family: "Open Sans";
    font-weight: bold;
    width: 90px;
    height: 41px;
    border-radius: 15px;
}

.rightButton:hover{
    color: white;
    background: #D39C3E;
}