.container{
    margin: 30px auto;
    width: 90%;
    height: 60px;
    align-content: center;
    justify-content: center;
    display:flex;
}
.container,.MarketViewerSlider, .PrivateAccountSlider
    li{
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight:bold;
       
    }
    .PrivateAccountSlider{
        margin: 30px auto;
        width: 75%;
        height: 60px;
        align-content: center;
        justify-content: center;
        display:flex;
    }
    .hrPrivate{
        width: 85%;
        margin-top: 43px;
        border: 0.5px solid #cccccc;
        position: absolute;
    }
    .MarketViewerSlider{
        margin: 30px auto;
        width: 55%;
        height: 60px;
        align-content: center;
        justify-content: center;
        display:flex;
        
    }
    .hr{
        width: 65%;
        margin-top: 43px;
        border: 0.5px solid #cccccc;
        position: absolute;
      }
