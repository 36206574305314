@import "../modals.scss";
@import "../../Modals//FilterModal//FilterModal.module.scss";

.modal_item{
  top: 55px;
  left: -55px;
  background: white;
  &::before {
    top: -30px;
    left: 43px;
  }
}

.modal_header_icon{
  margin-left: auto;
//  align-self: right;
 font-size: 23px;
}

.modal_body{
  padding: 30px 40px;
}

input::-webkit-input-placeholder { /* WebKit browsers */
    color: #CCCCCC;
}
input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #CCCCCC;
}
input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #CCCCCC;
}
input:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #CCCCCC;
}

.action_buttons{
  width: 100%;
  text-align: center!important;
  margin-bottom: 30px;
}

.settings_calendar_click_item{
  font-size: 18px;
  font-family: 'Open Sans';
  letter-spacing: 0;
  font-weight: bold;
}

.settings_calendar_modal_header{
  // padding-right: 197px;
}
