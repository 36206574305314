.modal_item{
  position: absolute;
  box-shadow: 0px 0px 5px 0px #444;
  border-radius: 0px 0px 20px 20px;
  font-weight: 500;
  z-index: 99;
  &::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 30px 30px 30px;
    border-color: transparent transparent #285A64 transparent;
  }
}
.modal_header{
  background: #285A64;
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Lato';
  padding-left: 15px;
}
.modal_body{
  background: white;
  border-radius: 0px 0px 20px 20px;
}

.modal_header_icon{
  margin-right: 15px;
}


.personalizations_body{
  padding: 20px 40px 40px;
  .personalizations_miniHeader{
    background: #285A64;
    color: white;
    font-weight: bold;
    padding: 5px 15px;
    box-sizing: border-box;
  }
  .personalizations_hrow {
    padding: 0 12px;
    & >[class*=col-]{
      padding-right: 3px;
      padding-left: 3px;
    }
  }
  .personalizations_lightgray{
    font-size: 12px;
  }
  input[type="checkbox"]{
    position: relative;
    height: 15px;
    width: 15px;
    -webkit-appearance: none;
    background: transparent;
    outline: none;
    border: 1px solid white;
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
    transition: .5s;
    cursor: pointer;
  }
  input[type="checkbox"]:before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 20px;
      top: 12%;
      left: 0;
      background: white;
      transform: scale(1.1);
      box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
      transition: .5s;
      border: 1px solid #cccccc;
  }
  input:checked[type="checkbox"]:before {
    left: 0px;
    background: #285A64;
  }
  .checkbox_row{
    span{
      margin-left: 5px;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
