.Language{
  color: rgb(88, 128, 136);
  display: block;
  padding: 15px;
  text-decoration: none;
  font-weight: 600;
  font-family: 'Open Sans';
}
.Language span{
  cursor: pointer;
}
.language_active{
  color: white;
}
