.graphContainer{
  width: 100%;
  padding: 20px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.currencyType{
  font-weight: bold;
  position: relative;
  margin-bottom: -15px;
  font-size: 26px;
  font-family: 'Open Sans';
}
.otherCurrency{
  font-weight: bold;
  font-size: 22px;
}
.graphActions{
  display: flex;
}
.graphActionsItem{
  display: flex;
  margin-right: 5px;
  padding-top: 9px;
  align-items: baseline;
}
.graphActionsItemType{
  flex:1;
  margin-right: 5px;
  font-weight: bold;
  padding-top: 14px;
  font-size: 20px;
  font-family: Lato;
}

.graphActionsItemValue{
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
}

.graphActionsItemValue:empty{
  padding-right: 108px;
}
.maximizeIcon{
  cursor: pointer;
  margin-top: 11px;
}

.minimizeIcon{
  cursor: pointer;
  margin-top: 17px;
}

.graphSeperator{
  height: 24px;
  width: 1px;
  background: #285A64;
  margin: 20px 10px 0 5px;

}

.graphSeperator2{
  height: 55px;
  width: 2px;
  background: #eee;;
  margin: 10px 10px 0 5px;

}
/* Maximize Effect */
.maximize{
  position: absolute;
  top: 0;
  left: 0;
  height: 200%;
  z-index: 99;
  padding: 20px;
  box-sizing: border-box;
  background: white;
}
.maximizeSecond{
  left:-100%;
}
.maximizeThird{
  top:-100%;
}
.maximizefourth{
  left:-100%;
  top:-100%;
}
.showInWidget{
  width: 100%;
}
.float{
  font-family: Lato;
  font-size: 33px;
  font-weight: bold;
}
.maxiGraphHeader{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.columnElement{
  margin-right: 7px;
}
.smallText{
  font-size: 13px;
  position: relative;
  margin-top: -6px;
}

@media only screen and (max-width: 360px) {

  .graphActionsItemType {
     font-size: 15px;
  }
  .graphActionsItemValue{
    font-size: 15px;
  }
  .float{
    font-size: 22px;
  }

}

@media only screen and (min-width: 1200px) and (max-width: 1385px) {

  .graphActionsItemType {
     font-size: 15px;
  }
  .graphActionsItemValue{
    font-size: 15px;
  }
  .float{
    font-size: 22px;
  }

}

@media only screen and (max-width: 991px) {
  .minimizeIcon{
    display: none;
  }
}
